import React, { useEffect, useState } from "react";
import Item from "./Item";
import client, { urlFor } from "../sanity";
import lines from "../utils/lines2.png";
import clock2 from "../utils/time2.png";
import clock3 from "../utils/time3.png";
import cold from "../utils/cold.png";

function Colds() {
  const [colds, setColds] = useState([]);
  useEffect(() => {
    client
      .fetch(
        `*[_type == "meal" && category->name == "Soğuk İçecekler"] | order(priority asc){
      ...,
     category ->{
       ...
     }
    }`
      )
      .then((data) => {
        setColds(data);
      });
  }, []);
  return (
    <>
      <div
        className="flex flex-wrap m-auto w-full justify-center space-y-2"
        name="Soğuk İçecekler"
      >
        {/* <div
					className={`bg-[#FEE7D7] flex flex-col w-full self-center items-center content-center rounded-lg h-[200px]`}
				>
					<img
						src={cold}
						className="w-[50px] mt-12 h-[50px] justify-center items-center"
					/>
					<h1
						className={` text-black text-4xl mb-1 font-extralight m-auto pb-3`}
					>
						Soğuk İçecekler
					</h1>
					<img
						src={lines}
						className="w-full relative content-end bottom-0 h-[25px] select-none rounded-b-lg"
					/>
				</div> */}
        <div className="flex h-[120px] bg-[#FEE7D7] w-full rounded-lg justify-center items-end relative">
          <div className="flex flex-col justify-center items-center">
            <img
              src={cold}
              alt="Soğuk İçecekler"
              className="w-[40px] mt-12 h-[40px] justify-center items-center"
            />
            <h2 className="text-4xl mb-2 text-black font-light">
              Soğuk İçecekler
            </h2>
          </div>
        </div>
        <div className="w-full grid grid-cols-2 gap-2">
          {colds?.map((item) => {
            return (
              <Item
                key={item._id}
                name={item.name}
                price={item.price}
                image={item.image}
                description={item.description}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Colds;
