import React from "react";
import { Flex, Button, Dialog, TextField, Text } from "@radix-ui/themes";
import logo from "../utils/logo.png";
import loader from "../utils/loader.png";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { urlFor } from "../sanity";
import { BsInfoCircle } from "react-icons/bs";
function Item({ name, description, price, image, onSale }) {
  return (
    <>
      <Dialog.Root>
        <Dialog.Trigger>
          <div
            name={name}
            className="bg-[#f0f0f0] flex flex-col justify-center items-center rounded-lg relative"
          >
            <LazyLoadImage
              alt={name}
              height={120}
              src={urlFor(image).url()} // use normal <img> attributes as props
              width={120}
              placeholderSrc={loader}
            />
            {/* <img
							height={120}
							width={120}
							loading="lazy"
							alt={name}
							results={10}
							src={urlFor(image).url()}
							className="max-w-[120px] lazy-img"
							style={{
								backgroundImage: `url(${loader})`,
								width: "100%",
								height: "100%",
								backgroundSize: "cover",
							}}
						/> */}
            <h3 className="font-bold text-center truncate max-w-[90%] text-sm text-[#71380F]">
              {name}
            </h3>
            {onSale === false || onSale === undefined ? (
              <p className="text-[#37a9ad] text-xl font-extralight">
                {price}
                <span className="text-[#71380F] z-10 absolute text-xs font-bold">
                  TL
                </span>
              </p>
            ) : (
              <div className="flex flex-row space-x-5">
                <p className="text-[#37a9ad] text-3xl font-extralight w-2/5 line-through	">
                  {price}{" "}
                  <span className="text-[#71380F] z-10 absolute text-xs font-bold">
                    TL
                  </span>
                </p>
                <p className="text-[#37a9ad] text-3xl font-extralight w-full justify-start">
                  {price / 2}{" "}
                  <span className="text-[#71380F] z-10 absolute text-xs font-bold">
                    TL
                  </span>
                </p>
              </div>
            )}
            <BsInfoCircle className="text-[#71380F] absolute right-2 bottom-2" />
          </div>
        </Dialog.Trigger>
        <Dialog.Content style={{ maxWidth: 450, color: "#71380F" }}>
          <Flex justify="center">
            <LazyLoadImage
              alt={name}
              height={200}
              src={urlFor(image).url()} // use normal <img> attributes as props
              width={200}
              placeholderSrc={loader}
            />
          </Flex>
          <Dialog.Title style={{ fontFamily: "Source Sans Pro" }}>
            {name}
          </Dialog.Title>
          <Flex direction="column" gap="3" style={{ color: "black" }}>
            {description}
          </Flex>
          <Flex gap="3" mt="4" justify="between">
            {onSale === false || onSale === undefined ? (
              <p className="text-[#37a9ad] text-2xl font-extralight">
                {price}
                <span className="text-[#71380F] z-10 absolute text-xs font-bold">
                  TL
                </span>
              </p>
            ) : (
              <div className="flex flex-row space-x-5">
                <p className="text-[#37a9ad] text-3xl font-extralight w-2/5 line-through	">
                  {price}{" "}
                  <span className="text-[#71380F] z-10 absolute text-xs font-bold">
                    TL
                  </span>
                </p>
                <p className="text-[#37a9ad] text-3xl font-extralight w-full justify-start">
                  {price / 2}{" "}
                  <span className="text-[#71380F] z-10 absolute text-xs font-bold">
                    TL
                  </span>
                </p>
              </div>
            )}
            <Dialog.Close>
              <Button
                autoFocus={false}
                variant="soft"
                radius="large"
                color="brown"
              >
                Tamam
              </Button>
            </Dialog.Close>
          </Flex>
        </Dialog.Content>
      </Dialog.Root>
    </>
    // <div
    // 	name={name}
    // 	className="bg-[#f0f0f0] grid grid-cols-2 w-full place-content-between self-center items-center content-center rounded-lg p-1"
    // >
    // 	<div className="flex flex-col space-y-3 p-2">
    // 		<h1 className="text-[#71380F] font-bold text-lg ">{name}</h1>
    // 		<p className="text-sm">{description}</p>
    // 		{onSale === false || onSale === undefined ? (
    // 			<p className="text-[#37a9ad] text-3xl font-extralight w-full">
    // 				{price}
    // 				<span className="text-[#71380F] z-10 absolute text-xs font-bold">
    // 					TL
    // 				</span>
    // 			</p>
    // 		) : (
    // 			<div className="flex flex-row space-x-5">
    // 				<p className="text-[#37a9ad] text-3xl font-extralight w-2/5 line-through	">
    // 					{price}{" "}
    // 					<span className="text-[#71380F] z-10 absolute text-xs font-bold">
    // 						TL
    // 					</span>
    // 				</p>
    // 				<p className="text-[#37a9ad] text-3xl font-extralight w-full justify-start">
    // 					{price / 2}{" "}
    // 					<span className="text-[#71380F] z-10 absolute text-xs font-bold">
    // 						TL
    // 					</span>
    // 				</p>
    // 			</div>
    // 		)}
    // 	</div>
    // 	<div className=" flex justify-end content-end m-auto items-end">
    // 		<img
    // 			loading="lazy"
    // 			alt={name}
    // 			src={urlFor(image).url()}
    // 			className="max-w-[150px]"
    // 		/>
    // 	</div>
    // </div>
  );
}

export default Item;
